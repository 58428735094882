/* global $ */

class App {
    bootstrap;
    menuMoving = false;

    constructor() {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms();
        this.initMatrixBlock();
        this.initBannerSlider();
        this.initFontAwesome();
        $('body').css('opacity', 1);
        console.log('App initialized');
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () {
            };
        }
    }

    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms() {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form'), (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"), (i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    initToasts(elems) {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems) {
        if (!elems.length) {
            return;
        }
        this.getBootstrap().then((bootstrap) => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    addAlert(message, $elem, type = "danger", dismissible = false) {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false) {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    initMatrixBlock() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
                new matrixblock.Matrixblock;
            });
        }
    }

    initBannerSlider() {
        if ($('.banner-img').length > 1) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.banner-slider').slick({
                    rows: 0,
                    autoplay: true
                });
            });
        }
    }
}

export default App;